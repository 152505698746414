<template>
    <div class="screen" style="height: 100%;">
        <Header v-if="!this.isguest" />
        <ion-content fullscreen scroll-y="false">
            <div class="page-content">
                <div v-if="this.socketStore.list && !this.isguest" class="list-title">
                    {{ this.socketStore.list.name }}
                </div>
                <div class="users" v-if="!this.isguest">
                    <div class="list-members">
                        <div v-for="(member, id) in this.listMembers" :key="id" class="user-container">
                            <div class="crown-wrapper" v-if="retrievedList.owner_id === member.user.id">
                                <img class="crown" :src="LogoPath" />
                            </div>
                            <div class="user-icon">
                                <span>
                                    {{ member.user.name.slice(0, 1).toUpperCase() + member.user.name.slice(1, 2) }}
                                </span>
                                <div class="online-indicator"></div>
                            </div>
                        </div>

                        <div v-for="(member) in this.listContributorOffline" :key="member.id" class="user-container">
                            <div class="crown-wrapper" v-if="retrievedList.owner_id === member.user_id">
                                <img class="crown" :src="LogoPath" />
                            </div>
                            <div class="user-icon">
                                <span>
                                    {{ member.name.slice(0, 1).toUpperCase() + member.name.slice(1, 2) }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="list-container" v-if="!this.isguest" style="height: calc(100vh - 200px); overflow-y: auto;">
                    <ion-card v-for="(item, id) in listItems" :key="id" class="carditem" :color="item.color">
                        <ion-card-header style="position: absolute; top: 15px; left: 15px;">
                            <span style="font-size: 16px; font-weight: bold; ">{{ id + 1 }}.</span>
                        </ion-card-header>
                        <ion-card-header style="position: absolute; top: 15px; right: 15px;">
                            <span style="font-size: 16px; font-weight: bold;">{{ getCreater(item) }}</span>
                        </ion-card-header>
                        <ion-card-header style="background: none; padding: 30px 15px">
                            <ion-card-title style="font-size: 35px;text-align: center; font-weight: bold;">
                                {{ item.name }}
                            </ion-card-title>
                        </ion-card-header>
                    </ion-card>
                </div>
                <div class="btns" v-if="!this.isguest">
                    <div class="share-buttons">
                        <ion-fab-button class="shareBtn" @click="openShareMenu">
                            <ion-icon name="share" size="large" color="contrast"></ion-icon>
                        </ion-fab-button>
                    </div>
                    <div>
                        <ion-button :disabled="!owner || listItems.length === 0" strong shape="round"
                            @click="spinWheel()">
                            <span style="font-size: 18px"> Okaayy lets goo! </span>
                        </ion-button>
                    </div>
                    <div class="edit-buttons">
                        <ion-fab-button class="updateBtn" @click="openAddItems">
                            <ion-icon name="md-create" size="large" color="contrast"></ion-icon>
                        </ion-fab-button>
                    </div>
                </div>

                <div class="page" v-if="this.isguest">
                    <div>
                        <div class="title-spacer">
                            <ion-text style="text-align:center">
                                <h1 class="title">Geef je gevens om mee te doen met de lijst</h1>
                            </ion-text>
                        </div>

                        <div class="list">
                            <ion-text style="text-align:center">
                                <h1 class="inputtext">naam</h1>
                            </ion-text>
                            <ion-input ref="input" type="text" :value="name" placeholder="naam"
                                @input="handleInputName($event.target.value)" v-on:keyup.enter="name"
                                class="customInput" required></ion-input>
                            <ion-text style="text-align:center">
                                <h1 class="inputtext">email</h1>
                            </ion-text>
                            <ion-input ref="input" type="text" placeholder="email" :value="email"
                                @input="handleInputEmail($event.target.value)" v-on:keyup.enter="email"
                                class="customInput" required></ion-input>

                            <p class="emailcheck" v-if="!isValid">geen naam ingevuld of Ongeldig Email</p>
                        </div>

                        <div class="btn">
                            <ion-button type="submit" class="join-list-btn" @click="openList">
                                <span style="font-weight: bold; font-size: 12px">Doe mee!!</span>
                            </ion-button>
                        </div>
                    </div>
                </div>
            </div>
        </ion-content>
    </div>
</template>

<script>
import { useSocketStore } from "../stores/Socket.js";
import listService from "../services/listService.js";
import Header from "../components/Header";
import { eventBus } from "../main";
import Sharemenu from "../components/ShareMenu";
import AddItemShared from "../components/AddItemShared.vue";
import firebase from 'firebase/app';
import 'firebase/firestore';


export default {
    name: 'SharedList',
    data() {
        return {
            socketStore: useSocketStore(),
            listItems: [],
            listContributorOffline: [],
            listContributor: [],
            isContributor: false,
            listofmembesnames: [],
            isguest: true,
            email: "",
            name: "",
            isValid: true,
            guestUser: {
                id: "",
                name: "",
                email: null,
            },
            retrievedList: {},
            shouldFetchData: false,
            owner: false,
            selectedTheme: localStorage.getItem('selectedTheme'),
        }
    },
    components: {
        Header,

    },
    computed: {
        listMembers() {
            console.log("socketmembers", this.socketStore.roomUsers)
            return this.socketStore.roomUsers;
        },
        LogoPath() {
            if (this.selectedTheme === 'bg') {
                return require("../assets/icons/Kroon-kjm-bg.svg");
            }
            else if (this.selectedTheme === 'ow') {
                return require(`../assets/icons/Kroon-kjm-wo.svg`);

            }
            else if (this.selectedTheme === 'pw') {
                return require(`../assets/icons/Kroon-kjm-wp.svg`);
            }
            else if (this.selectedTheme === 'bp') {
                return require("../assets/icons/Kroon-kjm-bp.svg");
            }
            else {
                // Default logo path when selectedTheme doesn't match any condition
                return require(`../assets/icons/Kroon-kjm-wo.svg`);
            }
        }
    },
    methods: {
        getCreater(item) {
            const creater = this.listContributor.find(contributor => contributor.user_id === item.created_by);
            return (creater.name.slice(0, 1).toUpperCase() + creater.name.slice(1, 2));
        },

        getListContributorNotOnline() {
            return new Promise((resolve, reject) => {
                try {
                    this.listofmembesnames = [];
                    this.listContributorOffline = [];
                    this.socketStore.roomUsers.forEach(item => {
                        this.listofmembesnames.push(item.user.name);
                    });
                    this.listContributor.forEach(item => {
                        // Check if the item is not already in listContributorOffline, not an empty name, and not already present in listContributorOffline
                        if (!this.listofmembesnames.includes(item.name) && item.name !== "" && !this.listContributorOffline.some(existingItem => existingItem.name === item.name)) {
                            // Push the item to listContributorOffline
                            this.listContributorOffline.push(item);
                        }
                    });

                    resolve(this.listContributorOffline); // Resolve the promise with the list of offline contributors
                } catch (error) {
                    reject(error); // If an error occurs, reject the promise with the error
                }
            });
        },



        CheckIfUserAlreadyIsContributor() {
            this.listContributor = [];
            firebase.firestore()
                .collection('List_contributors').where('list_id', '==', this.$route.params.listId).get()
                .then(querySnapshot => {
                    querySnapshot.forEach(doc => {
                        const List_contributors = doc.data();
                        List_contributors.id = doc.id; // Add the document ID to the item object
                        this.listContributor.push(List_contributors);
                    });

                    this.listContributor.forEach(item => {
                        if (item.user_id === firebase.auth().currentUser.uid && item.user_id != null) {
                            this.isContributor = true;

                        }

                    });
                    this.createContributor();
                })
                .catch(error => {
                    console.log('Error getting items: ', error);
                });

        },

        createContributor() {
            if (firebase.auth().currentUser.uid !== null && this.isContributor == false) {
                console.log("hahahahaha")
                const listid = this.$route.params.listId;
                if (listid !== null) {
                    listService.createListContributor(listid);
                }
            }
            this.isContributor = false;

        },


        openAddItems() {
            return this.$ionic.modalController
                .create({
                    component: AddItemShared,
                    componentProps: {
                        // this adds a value to the listId property in the component Data section ~ youri
                        data: {
                            listId: this.$route.params.listId,
                            itemList: this.listItems,
                            guestUser: this.guestUser
                        },
                        items: this.list,

                    },
                })
                .then(function (modalElement) {
                    modalElement.present().then(() => { });
                });
        },

        openShareMenu() {
            return this.$ionic.modalController
                .create({
                    component: Sharemenu,
                })
                .then(function (modalElement) {
                    modalElement.present().then(() => { });
                });

        },
        async showJoinedToast(name) {
            console.log('Joined name in toast: ', name);
            let toastMessage = name + ' joined!';

            if (name == null) {
                toastMessage = 'You joined!';
            }

            const successToast = await this.$ionic.toastController.create({
                message: toastMessage,
                duration: 1000,
                animated: true,
                position: "top",
                color: "primary",
                cssClass: 'toast'
            });

            await successToast.present();
        },
        async showLeaveToast(name) {
            let toastMessage = name + ' left!';

            const leaveToast = await this.$ionic.toastController.create({
                message: toastMessage,
                duration: 1000,
                animated: true,
                position: "top",
                color: "primary",
                cssClass: 'toast'
            });
            await leaveToast.present();
        },
        async getListById() {
            const list = await listService.getListById(this.$route.params.listId);
            console.log("RETRIEVED LIST: ", list);
            list.id = this.$route.params.listId;
            this.socketStore.list = list;
            console.log('LIST IN SOCKET STORE: ', this.socketStore.list);
            return list;
        },
        checkIfUserIsGuest() {
            if (firebase.auth().currentUser == null) {
                this.isguest = true
            }
            else {
                this.isguest = false
            }
        },

        //guest methods

        isValidEmail(email) {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailRegex.test(email);
        },

        handleInputName(value) {
            this.name = value;
        },
        handleInputEmail(value) {
            this.email = value;
        },

        CheckIfGuestUserAlreadyIsContributor() {
            return new Promise((resolve, reject) => {
                if (firebase.auth().currentUser !== undefined) {
                    this.listContributor = [];
                    firebase.firestore()
                        .collection('List_contributors').where('list_id', '==', this.$route.params.listId).get()
                        .then(querySnapshot => {
                            querySnapshot.forEach(doc => {
                                const List_contributors = doc.data();
                                List_contributors.id = doc.id; // Add the document ID to the item object
                                this.listContributor.push(List_contributors);
                            });

                            this.listContributor.forEach(item => {
                                if (item.email === this.guestUser.email) {
                                    this.isContributor = true;
                                }
                            });
                            console.log("broski", this.listContributor);
                            this.createGuestContributor();
                            resolve(); // Resolve the Promise when the query is complete
                        })
                        .catch(error => {
                            console.log('Error getting items: ', error);
                            reject(error); // Reject the Promise if there's an error
                        });
                }
            });

        },

        createGuestContributor() {
            if (this.isContributor == false) {
                console.log("hahahahaha")
                const listid = this.$route.params.listId;
                if (listid !== null) {
                    listService.createGuestListContributor(listid, this.guestUser);
                }
            }
            this.isContributor = false
        },

        openList() {
            console.log(this.name)
            console.log(this.email)
            if (this.name !== "") {
                this.isValid = false;
                this.isValid = this.isValidEmail(this.email)
            }
            if (this.isValid && this.email != "" && this.name != "") {
                this.guestUser.id = this.name + "1";
                this.guestUser.name = this.name;
                this.guestUser.email = this.email;
                this.isguest = false;
                this.socketStore.setupSocketConnection(this.retrievedList.id, this.guestUser);
                this.CheckIfGuestUserAlreadyIsContributor().then(() => {
                    this.getListContributorNotOnline();
                });
            }
        },

        async spinWheel() {
            console.log("spinwheel")
            const listid = encodeURIComponent(JSON.stringify(this.retrievedList.id));
            const user = encodeURIComponent(JSON.stringify(this.guestUser));
            var spinned = false;
            console.log(this.retrievedList.winner_id)
            if (!this.retrievedList.winner_id && this.owner) {
                await this.pickWinner();
            }
            if (this.owner) {
                this.socketStore.sendWinner(this.retrievedList.id);
            }
            this.$router.push({ name: 'sharedwheel', params: { listid, user, spinned } });
        },

        async pickWinner() {
            let index = Math.floor(Math.random() * this.listItems.length);
            let winner = this.listItems.at(index);
            await listService.setWinner(this.retrievedList.id, winner.id)
            console.log("winner", winner);
            console.log("index", index)
        },

        async sendToResult() {
            console.log("sendtoresult")
            const listid = encodeURIComponent(JSON.stringify(this.retrievedList.id));
            const user = encodeURIComponent(JSON.stringify(this.guestUser));
            var spinned = true;
            console.log(this.retrievedList.winner_id)
            this.$router.push({ name: 'sharedwheel', params: { listid, user, spinned } });
        }

    },
    async mounted() {

        this.checkIfUserIsGuest()
        this.retrievedList = await this.getListById();
        if (!this.isguest) {
            this.socketStore.setupSocketConnection(this.retrievedList.id, this.guestUser);
            if (this.retrievedList.owner_id == firebase.auth().currentUser.uid) {
                this.owner = true;
            }
        }
        this.listId = this.$route.params.id;
        if (this.listId != "") {
            try {
                this.listData = await listService.getListById(this.listId);
                var contributor_id = localStorage.getItem("contributor_id");

                if (contributor_id) {
                    try {
                        var contributor = await listService.getListContributor(contributor_id);
                        if (contributor.list_id == this.listId) {
                            if (contributor.is_ready) {
                                this.isReady = true;
                            }
                            this.hasName = true;
                        }

                    } catch (error) {
                        console.log(error);
                    }
                }
            } catch (error) {
                //window.location.replace("http://kiesjijmaar.com");
            }
        }

        if (firebase.auth().currentUser !== null) {
            this.CheckIfUserAlreadyIsContributor();
        }
        if (this.retrievedList.winner_id) {
            this.sendToResult();
        }

    },

    beforeRouteEnter(to, from, next) {
        next(async vm => {
            const listId = to.params.listId
            try {
                // Retrieve the list asynchronously
                vm.retrievedList = await listService.getListById(listId);
                // Check if the guest user is already a contributor
                await vm.CheckIfGuestUserAlreadyIsContributor();
                // After checking, get the list of contributors not online
                vm.getListContributorNotOnline();
            } catch (error) {
                console.error("Error:", error);
                // Handle the error appropriately, e.g., redirect to an error page
                next('/error');
            }
        });
    },


    beforeRouteLeave(to, from, next) {

        this.socketStore.disconnectSocket()
            .then(() => {
                // Socket disconnected successfully, navigate
                next();
            })
            .catch(error => {
                // An error occurred while disconnecting socket, still navigate
                console.error("Error disconnecting socket:", error);
                next();
            });
    },





    async created() {
        eventBus.$on("new-user-joined", (user) => {
            console.log("Joined new user:   ", user)
            if (user == null) {
                this.showJoinedToast(null);
                this.getListContributorNotOnline()
                return;
            }
            this.showJoinedToast(user.name)
            this.getListContributorNotOnline()
        }),
            eventBus.$on("user-left", (user) => {
                console.log("received eventbus message: ", user.name);
                this.getListContributorNotOnline()
                this.showLeaveToast(user.name)
            }),
            eventBus.$on("additems", (items, contributor_id, listid) => {
                this.socketStore.createItems(items, contributor_id, listid)
            })
        eventBus.$on("items-grouplist", (items) => {
            this.listItems = [];
            const computedStyle = getComputedStyle(document.documentElement);
            const ionColorcontrast = computedStyle.getPropertyValue('--ion-color-secondary');
            const ionColorPrimary = computedStyle.getPropertyValue('--ion-color-primary');
            let previeusColor = ionColorcontrast;
            items.items.forEach((item) => {
                if (previeusColor === ionColorcontrast) {
                    item.color = "primary";
                    previeusColor = ionColorPrimary;
                    this.listItems.push(item)
                } else if (previeusColor === ionColorPrimary) {
                    item.color = "contrast";
                    previeusColor = ionColorcontrast;
                    this.listItems.push(item)
                }
            })
        });

        eventBus.$on("get-winner", () => {
            console.log("eventbus")
            if (!this.owner) {
                console.log("isnt owner")
                this.spinWheel();
            }
        })

    },
}
</script>

<style scoped>
/* Your component's CSS goes here */
.users {
    margin-top: 20px;
}

.crown {
    width: 35px;
}

.page {
    display: flex;
    justify-content: center;

}

.btn {
    position: fixed;
    bottom: 5%;
    left: 50%;
    transform: translateX(-50%);
}


.customInput {
    margin-bottom: 10px;
    border-bottom: 3px solid var(--ion-color-primary);
    transition: border-bottom 0.2s ease-in-out;
    max-width: 80%;
    display: inline-block;
}

.list {
    text-align: center;
}

.screen {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.online-indicator {
    width: 8px;
    /* Adjust the width of the indicator */
    height: 8px;
    /* Adjust the height of the indicator */
    background-color: green;
    border-radius: 50%;
    /* Makes the indicator round */
    position: absolute;
    top: -4px;
    /* Adjust the position of the indicator */
    right: -4px;
    /* Adjust the position of the indicator */
}



.list-members {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    flex-wrap: wrap;
    padding-bottom: 10px;
}

.crown-wrapper {
    position: absolute;
    top: -25px;
    /* Adjust this value as needed */
    left: 43%;
    transform: translateX(-50%);
    z-index: 1;
}

.user-container {
    margin: 3px;
    margin-top: 0px;
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.user-icon {
    position: relative;
    border-radius: 50%;
    /* Adjust this value if needed */
    font-weight: bold;
    font-size: 20px;
    width: 40px;
    /* Adjust this value if needed */
    height: 40px;
    /* Adjust this value if needed */
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
    background-color: var(--ion-color-primary);
    color: var(--ion-color-primary-contrast);
}


.addItemButton {
    border-radius: 12px;
    font-weight: bold;
    width: 150px;
    height: 40px;
    margin-left: auto;
}

.list-title {
    font-size: 22px;
    font-weight: bold;
    font-family: Nunito, sans-serif;
    text-align: center;
    padding-top: 10px;
    height: auto;
}

.btns {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* Center items vertically */
    padding: 10px;
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: var(--ion-color-primary-contrast);
}

.share-buttons,
.edit-buttons {
    flex: 0 0 auto;
    /* Ensure buttons don't grow */
}


.itemId {
    font-size: 25px;
    display: flex;
    justify-content: center;
    width: 40px;
    height: 40px;

}

.carditem {
    border-radius: 10px;
    height: 180px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 3px solid var(--ion-color-primary);
    color: var(--ion-color-primary);
    flex: 0 0 65%;
    /* Adjust the width of each card item */
    margin-bottom: 20px;

}

.list-container {
    min-height: 73vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding-bottom: 50px;
}

.card {
    height: 60px;
    width: 360px;
    margin-bottom: 20px;
    border-radius: 15px;
    margin-right: 20px;
    background-color: var(--ion-color-primary)
}

.my-icon {
    height: 45px;
    margin-bottom: 5px;

}

#my-svg-path {
    fill: red;
    /* Change this to the desired color */
}
</style>
