<template>
    <div>
      <ion-header no-border>
        <Toolbar>
          <template #start>
            <ion-buttons slot="start">
              <ion-button @click="closeModal">
                <ion-icon
                  size="large"
                  name="ios-arrow-round-back"
                ></ion-icon>
              </ion-button>
            </ion-buttons>
          </template>
          <template #logo>
            <p />
          </template>
        </Toolbar>
      </ion-header>
  
      <ion-content fullscreen scroll-y="false">
        <ion-grid>
            <ion-row class="fade-in" ion-align-self-start>
                <ion-col size="11" ion-align-items-center class="col">
                <WhatsApp-button class="share-button--circle share-button--outline" btnText="Stuur een invite via whatsapp" />
            </ion-col>
        </ion-row>

        <ion-row class="fade-in" ion-align-self-end>
          <ion-col size="11" class="col">
            <Facebook-button class="share-button--circle share-button--outline" btnText="Stuur een invite via facebook" />
          </ion-col>
        </ion-row>
        <ion-row class="fade-in" ion-align-self-end>
          <ion-col size="11" ion-align-items-center class="col">
            <Email-button class="share-button--circle share-button--outline" style="color: var(--ion-color-primary);" btnText="Stuur een invite via de mail" />
          </ion-col>
        </ion-row>
        <ion-row class="fade-in" ion-align-self-end >
          <ion-col size="11" style="text-align: center !important;" ion-align-items-center class="col"> 
        <ion-button id="copybtn"
        type="submit"
        expand="block"
        shape="round"
        class="ion-margin-vertical"
        @click="copy"
      >
      <ion-icon name="copy" color="contrast">
      </ion-icon>
      Of copy de link
      </ion-button>
  </ion-col>
</ion-row>
      </ion-grid>
      </ion-content>
    </div>
  </template>
  
  <script>
  import Toolbar from "../components/Toolbar";
  import WhatsAppButton from "vue-share-buttons/src/components/WhatsAppButton";
import FacebookButton from "vue-share-buttons/src/components/FacebookButton";
import EmailButton from "vue-share-buttons/src/components/EmailButton";
  
  export default {
    data() {
        return {
           
        }
    },
    
    components: {
        Toolbar,
        WhatsAppButton,
        FacebookButton,
        EmailButton,
    },
    methods: {
        closeModal() {
            console.log("close")
      this.$ionic.modalController.dismiss();
    },
    copy(){
      console.log("copy")
      const textToCopy = "http://localhost:8080"+window.location.pathname;
      navigator.clipboard.writeText(textToCopy)
        .then(() => {
          alert('Text copied to clipboard: ' + textToCopy);
        })
        .catch(err => {
          console.error('Could not copy text: ', err);
        });
    },
    },
  };
  </script>
  
  <style scoped>
  .share-button--circle{
    min-width: 75%;
  }
  #copybtn{
    display: block;
    margin: auto;
    width: 75%;

  }
  .col{
    min-width: 100%;
    margin-top: 0.5rem;
    text-align:  center;
  }



  </style>
  