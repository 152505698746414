<template>
  <div>
    <ion-header no-border animated>
      <template #start>
        <ion-buttons slot="start">
          <ion-button @click="closeModal">
            <ion-icon size="large" color="light" name="close"></ion-icon>
          </ion-button>
        </ion-buttons>
      </template>
      <template #logo>
        <p />
      </template>
    </ion-header>
    <ion-content fullscreen class="ion-padding">
      <ion-card id="addItemCard" class="add-form">
        <ion-card-title>
          <h3 style="text-align:center;font-size:1.5rem;-ion-color-warning">
            {{ chosenItem ? chosenItem.item.titel : "Keuze toevoegen" }}
          </h3>
        </ion-card-title>
        <ion-card-content>
          <ion-col>
            <div class="fade-in" id="inputs">
              <ion-item v-for="(input, index) in inputs" :key="index" class="input-item" lines="none" ref="inputs">
                <ion-label position="stacked">Keuze {{ index + 1 }}:</ion-label>
                <div class="inputs">
                  <ion-input :value="input.name" v-bind:id="`input-${index}`" required
                    @input="input.name = $event.target.value" v-on:keyup.enter="nextInput(index)"></ion-input>
                  <ion-icon class="removeicon" name="remove" size="large" @click="removeRow(index)">
                  </ion-icon>
                </div>
              </ion-item>

              <div v-if="chosenItem && chosenItem.item.type === 'hotel'">
                <ion-item class="customInput">
                  <ion-label position="stacked">Waar?</ion-label>
                  <ion-input inputmode="text" color="light" :value="hotel.plaats"
                    @input="hotel.plaats = $event.target.value"></ion-input>
                </ion-item>
              </div>
            </div>
          </ion-col>
          <!-- <ion-col>
              <ion-button
                type="submit"
                expand="block"
                color="danger"
                shape="round"
                class="ion-padding-vertical ion-margin-vertical ion-padding-horizontal"
              >Toevoegen</ion-button>
          </ion-col> -->
        </ion-card-content>

        <div class="add-form-btn">
          <ion-icon name="add" size="large" @click="addRow" background-color="primary" color="secondary"></ion-icon>
        </div>
      </ion-card>
      <ion-button type="submit" expand="block" shape="round" class="ion-margin-vertical ion-padding-horizontal"
        @click="addItems">
        <span v-if="!continueButton">Opslaan</span>
        <span v-if="continueButton">Vul minimaal 1 keuze in</span>
      </ion-button>
    </ion-content>
  </div>
</template>
<script>

import firebase from "firebase";
import { eventBus } from "../main";

export default {
  props: ["chosenItem"],
  data() {
    return {
      createrId: "",
      listId: "",
      itemList: [],
      continueButton: false,
      inputs: [{ name: "" }],
      myArray: [],
      text: null,
      omschrijving: null,
      hotel: {
        plaats: null,
      },
    };
  },
  components: {
  },
  computed: {},
  methods: {
    itemsCheck() {
      var check = true;
      this.inputs.forEach((item) => {
        if (item.name.length <= 0) {
          check = false;
        }
      });
      return check;
    },
    addItems() {
        if (firebase.auth().currentUser !== null) {
          this.createrId = firebase.auth().currentUser.uid;
        }
        else {
          this.createrId = this.guestUser.id;
        }
        eventBus.$emit(`additems`, this.inputs, this.createrId, this.listId);

        this.showToast();
        this.closeModal();
    },

    closeModal() {
      this.$ionic.modalController.dismiss();
      eventBus.$emit("closeListTypeMenu");
    },
    async showToast(status) {
      if (status === "error") {
        const errorToast = await this.$ionic.toastController.create({
          message:
            '<ion-icon name="warning"></ion-icon>  Vul een naam voor je item in.',
          duration: 1600,
          position: "top",
          color: "primary",
          cssClass: "toast",
        });
        await errorToast.present();
      } else {
        const successToast = await this.$ionic.toastController.create({
          message:
            '<ion-icon name="filing"></ion-icon>  Uw items zijn opgeslagen!',
          duration: 1000,
          animated: true,
          position: "top",
          color: "primary",
          cssClass: "toast",
        });
        await successToast.present();
      }
    },
    addRow() {
      //Add input
      this.inputs.push({
        name: "",
      });

      this.totalInput = Object.keys(this.inputs).length;

      //Focus last input
      setTimeout(() => {
        this.$refs.inputs[
          this.inputs.length - 1
        ].children[1].children[0].setFocus();
      }, 20);
    },
    removeRow(index) {
      this.inputs.splice(index, 1);
    },
    nextInput(index) {
      //Focus next input, create new input if it's the last one
      if (this.inputs[index + 1]) {
        this.$refs.inputs[index + 1].children[1].children[0].setFocus();
      } else {
        this.addRow();
      }
    },
  },
  mounted() {
    var contributorId;
    if (firebase.auth().currentUser !== null) {
      contributorId = firebase.auth().currentUser.uid;
    }
    else {
      contributorId = this.guestUser.id;
    }

    var myItems = this.itemList.filter(
      (item) => item.created_by == contributorId
    );
    this.myArray = [];
    if (myItems) {
      this.myArray = myItems;
    }

    if (this.myArray.length > 0) {
      this.inputs = [];
      this.myArray.forEach((item) => {
        this.inputs.push({
          name: item.name,
          id: item.id,
        });
      });

      //Focus last input
      setTimeout(() => {
        this.$refs.inputs[
          this.inputs.length - 1
        ].children[1].children[0].setFocus();
      }, 50);
    } else {
      setTimeout(() => {
        this.$refs.inputs[0].children[1].children[0].setFocus();
      }, 50);
    }
  },
};
</script>

<style scoped>
h1,
h2,
h3,
h4 {
  font-family: "Nunito", sans-serif;
  font-weight: bold;
  color: var(--ion-color-primary);
}

/* ion-item{
  --highlight-color-focused: tomato;
} */
ion-item.item-has-focus>ion-label {
  transition: color 0.3s ease-in-out;
}

ion-item.item-has-focus>div>ion-input {
  border-bottom: 1px solid !important;
  transition: border-bottom 0.3s ease-in-out;
  padding-right: 50px !important;
}

ion-input {
  font-weight: bold;
  border-bottom: 1px solid;
  padding-right: 20px !important;
  max-width: 95%;
}

.removeicon {
  right: 0;
  position: absolute;
}

.add-form {
  border-radius: 20px 20px 0 20px;
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.14);
  box-shadow: rgba(0, 0, 0, 0.14) 1px 1px 20px 10px;
  position: relative;
}

.custom-card {
  padding: 100px;
}

/* .ion-icon.ios.icon-large.hydrated {
  top: -50px;
} */

.input-item {
  animation: fade-in 0.6s ease;
}

@keyframes fade-in {
  0% {
    opacity: 0;
    transform: translateY(-5px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.add-form-btn {
  position: absolute;
  right: 0;
  bottom: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  height: 40px;
  width: 40px;
  border-radius: 10px 10px 0 10px;
  font-size: 24px;
  background-color: var(--ion-color-primary);
}

.toast {
  text-align: center;
}

.inputs {
  display: flex;
  flex-direction: row;
  min-width: 80%;
  align-items: center;
}

ion-card-content {
  margin-bottom: 50px;
  padding-right: 5px !important;
}
</style>
